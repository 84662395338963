import React, { useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyD5Ed8mcYLMZIbzyFHw9ijj59ZLPiyBys8",
    authDomain: "radu-chat.firebaseapp.com",
    projectId: "radu-chat",
    storageBucket: "radu-chat.appspot.com",
    messagingSenderId: "492309891145",
    appId: "1:492309891145:web:c221f6b1275a9780d81798",
    measurementId: "G-W27PL73ZHD"
})
const auth = firebase.auth();
const firestore = firebase.firestore();

function App() {
  const [user]=useAuthState(auth)
  return (
    <div className="App">
      <header>
        <h1>Radu Chat</h1>
        <SignOut />
      </header>
      <section>
        {user ? <Chat /> : <SignIn />}
      </section>
    </div>
  )
}

function Chat() {
  const messageRef=firestore.collection('messages')
  const query=messageRef.orderBy('createdAt').limit(25)
  const [messages]=useCollectionData(query, {idField: 'id'})
  const [formValue, setFormValue] = useState('')
  const dummy=useRef()
  const sendMessage=async (e)=>{
    e.preventDefault()
    const {uid, photoURL}=auth.currentUser
    await messageRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid, 
      photoURL
    })
    setFormValue('')
    dummy.current.scrollIntoView({behavior: 'smooth'})
  }
  return (
    <>
      <main>
        {messages && messages.map(msg=> <Message key={msg.id} message={msg}/>)}
        <div ref={dummy}></div>
      </main>
      <form onSubmit={sendMessage}>
        <input placeholder="Mesaj" value={formValue} onChange={e=>setFormValue(e.target.value)} required maxLength="41"/>
        <button type="submit">✈</button>
      </form>
    </>
  )
}

function Message(props){
  const { text, uid, photoURL } = props.message;
  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';
  return (<>
    <div className={`message ${messageClass}`}>
      <img src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
      <p>{text}</p>
    </div>
  </>)
}

function SignIn() {
  const signInGoogle=()=>{
    const provider=new firebase.auth.GoogleAuthProvider()
    auth.signInWithPopup(provider)
  }
  return (
    <button onClick={signInGoogle} className="sign-in">Intra cu Google</button>
  )
}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Iesi</button>
  )
}

export default App;
